<template>
  <div class="history-timeline">
    <div ref="chart" :style="chartStyle"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'historyTimeline',
  data() {
    return {
      chart: null,
      chartStyle: {
        width: '100%',
        height: '100vh'
      },
      events: [
        { icon: '' ,year: '2009', title: '', content: '网营科技成立' },
        { icon: require('@/assets/iconfont/01.png'),year: '2011', title: '行业首批自有\nERP/CRM系统服务商', content: '网营升级\n自研技术能力' },
        { icon: require('@/assets/iconfont/02.png') ,year: '2016', title: '淘系首批内容合作伙伴', content: '网营布局\n内容营销版块' },
        { icon: require('@/assets/iconfont/03.png') ,year: '2018', title: '首批天猫数据\n银行认证服务商', content: '网营布局\n数字营销版块' },
        { icon: require('@/assets/iconfont/04.png') ,year: '2019', title: '首批抖音服务商', content: '网营发力\n直播电商版块' },
        { icon: require('@/assets/iconfont/05.png') ,year: '2021', title: 'APP/小程序/视频\n号/SaaS解决方案', content: '网营布局\n私域版块' },
        { icon: require('@/assets/iconfont/08.png') ,year: '2022', title: '', content: '网营布局\nAI领域' },
        { icon: require('@/assets/iconfont/06.png') ,year: '2023', title: '', content: '全面升级数智仓储\n消费者中心升级' },
        { icon: require('@/assets/iconfont/07.png') ,year: '2024', title: '闪电仓/O2O全渠道', content: '网营布局即时零售' }
      ]
    }
  },
  mounted() {
    this.initChart()
    window.addEventListener('resize', this.resizeChart)
    // 初始调用一次 resizeChart 确保正确渲染
    this.$nextTick(() => {
      this.resizeChart()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeChart)
    this.chart && this.chart.dispose()
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart)

      // 根据屏幕宽度调整字体大小和间距
      const screenWidth = window.innerWidth
      const baseFontSize = screenWidth / 100 // 基础字体大小
      
      // 生成数据点，设置不同的 y 坐标来创建起伏
      const data = this.events.map((event, index) => {
        // 定义每个点的 y 坐标，创建起伏效果
        const yPositions = [
          240,   
          290,   
          320,   
          280,   
          340,   
          300,   
          350,   
          310,   
          380    
        ]

        return {
          value: [index * 300, yPositions[index]],
          itemStyle: { opacity: 1 },
          label: {
            show: true,
            position: index % 2 === 0 ? 'top' : 'bottom',
            distance: index % 2 === 0 ? baseFontSize * 3 : baseFontSize * 2.5,
            formatter: function(params) {
            let result = `{title|${event.title}}\n{content|${event.content}}\n{year|${event.year}}`
            if (event.icon) {
              result += `\n{blank|}{icon|}`  // 添加一个空白区域用于控制icon图片paddingLeft
            }
            return result
          },
            rich: {
              blank: {
                width: baseFontSize * 0.5,
                height: baseFontSize * 2
              },
              title: {
                fontSize: baseFontSize * 0.8,
                padding: [0, 0, baseFontSize, baseFontSize * 0.5],
                lineHeight: baseFontSize * 1.4
              },
              content: {
                fontSize: baseFontSize * 1.2,
                padding: [baseFontSize * 2.5, 0, baseFontSize * 2.5, baseFontSize * 0.5],
                lineHeight: baseFontSize * 2
              },
              year: {
                fontSize: baseFontSize * 1.1,
                fontWeight: 'bold',
                padding: [baseFontSize, 0, baseFontSize, baseFontSize * 0.5]
              },
              icon: {
                backgroundColor: {
                  image: event.icon
                },
                height: baseFontSize * 2,
                width: baseFontSize * 2,
                align: 'left',
              }
            }
          },
          // 添加白色渐变线配置
          itemStyle: {
            color: '#fff',
            borderWidth: 0
          },
          emphasis: {
            disabled: true
          }
        }
      })

       // 添加首尾点（不显示）
      data.unshift({
        value: [-300, 200], // 起点位置
        itemStyle: { opacity: 0 }
      })
      data.push({
        value: [data.length * 300, 520], // 终点位置
        itemStyle: { opacity: 0 }
      })

      const option = {
        grid: {
          left: '10%',
          right: '10%',
          top:'50%',//图标距离顶部位置
          bottom: '25%'
        },
        xAxis: {
          type: 'value',
          show: false,
          min: -300,
          max: data.length * 300,  // 固定 x 轴范围
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [{
          type: 'line',
          smooth: 0.5,//曲线的平滑度，数字越大越平滑
          smoothMonotone: 'x',
          symbol: 'circle',
          symbolSize: 12,
          lineStyle: {
            color: '#fff',
            width: 4
          },
          itemStyle: {
            color: '#fff'
          },
          label: {
            color: '#fff',
            align: 'left'
          },
          data: data
        },
        // 添加渐变线系列
        {
          type: 'custom',
          renderItem: function (params, api) {
            // 排除首尾两个点
            if (params.dataIndex <= 0 || params.dataIndex >= data.length - 1) {
              return;
            }
            const point = api.coord([api.value(0), api.value(1)])
            // 调整索引以匹配实际数据点的奇偶性
            const isTop = (params.dataIndex - 1) % 2 === 0
            const height = 120
            
            return {
              type: 'group',
              children: [{
                type: 'rect',
                shape: {
                  x: point[0] - 1,
                  y: isTop ? point[1] - height + 20 : point[1] + 10,  // 修改偏移量
                  width: 2,
                  height: height - 10  // 减少相应的高度
                },
                style: {
                  fill: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [{
                      offset: 0,
                      color: '#ffffff'
                    }, {
                      offset: 1,
                      color: 'rgba(255,255,255,0)'
                    }]
                  )
                }
              }]
            }
          },
          data: data
        }
      ]
      }

      this.chart.setOption(option)
    },
    resizeChart() {
      if (this.chart) {
        const container = this.$refs.chart;
        const parentWidth = container.parentElement.clientWidth;
        const screenHeight = window.innerHeight;
        
        // 更新图表容器样式
        this.chartStyle = {
          width: parentWidth + 'px',
          height: screenHeight * 1 + 'px' // 100vh
        }
        
        this.chart.resize();
        
        // 重新计算网格位置
        const gridTop = screenHeight * 0.4;
        this.chart.setOption({
          grid: {
            left: '10%',
            right: '10%',
            top: gridTop + 'px',
            bottom: screenHeight * 0.2 + 'px'
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.history-timeline {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  padding-top: 20px;
  font-size: 40px;
  margin-bottom: 40px;
}
</style>